<template>
	<div>
		<v-tabs
			v-model="tab"
			align-with-title
			class="elevation-0 ml-0"
			elevation="0"
		>
			<v-tab v-for="(tab, index) in tabs" :key="'tab-' + index">
				{{ tab }}
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab" touchless>
			<v-tab-item>
				<v-card>
					<v-card-text class="d-flex">
						<div>
							<v-avatar size="120" class="me-6">
								<v-img
									:src="
										profile.publicPictureUrl ||
										require('@/assets/images/avatars/default.svg')
									"
								/>
							</v-avatar>
							<v-btn
								color="primary"
								class="me-3 mt-5"
								@click="$refs.refInputEl.click()"
							>
								<v-icon class="d-sm-block" :class="{ 'mr-2': isSmAndUp }">
									mdi-cloud-upload-outline
								</v-icon>
								<span class="d-none d-sm-block">프로필 업로드</span>
							</v-btn>

							<input
								ref="refInputEl"
								@change="uploadProfileFile($event)"
								type="file"
								accept="*"
								:hidden="true"
							/>
						</div>
					</v-card-text>
					<v-card-text class="px-2">
						<v-form class="multi-col-validation mt-6">
							<v-row>
								<v-col cols="12" md="6">
									<validation-observer ref="observer" v-slot="{ valid }">
										<v-row no-gutters>
											<v-col cols="9" class="pr-1">
												<validation-provider
													v-slot="{ errors }"
													name="email"
													:rules="
														profile.email === originProfile.email
															? 'required|email'
															: 'required|isEmailUnique|email'
													"
												>
													<v-text-field
														v-model="profile.email"
														:error-messages="errors"
														label="이메일"
														dense
														outlined
													/>
												</validation-provider>
												<p
													class="text-xs pl-3"
													:class="{ 'success--text': profile.emailVerified }"
												>
													{{
														profile.emailVerified
															? '인증된 이메일 입니다'
															: '이메일 인증이 필요합니다'
													}}
												</p>
											</v-col>
											<v-col cols="3">
												<v-btn
													@click="requestVerificationEmail"
													:loading="requestVerificationEmailCodeLoading"
													:disabled="!valid"
													color="primary"
													class="verification-btn"
												>
													인증요청
												</v-btn>
											</v-col>
										</v-row>
									</validation-observer>
									<v-row no-gutters v-if="isVerificationEmailCodeRequested">
										<v-col cols="9" class="pr-1">
											<v-text-field
												ref="emailCodeInput"
												v-model="verificationEmailCode"
												label="이메일 인증코드"
												placeholder="이메일로 발송된 인증코드를 입력해주세요"
												dense
												outlined
											/>
										</v-col>
										<v-col cols="3">
											<v-btn
												@click="verifyEmailCode"
												:loading="verifyEmailCodeLoading"
												color="primary"
												class="verification-btn"
											>
												확인
											</v-btn>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="12" md="6" sm="12">
									<validation-observer ref="observer">
										<v-row no-gutters>
											<!-- <v-col
												cols="12"
												md="3"
												sm="3"
												:class="isMdAndUp ? 'pr-1' : 'pr-0 mb-1'"
											>
												<v-select
													v-model="countryCode"
													:items="countryCodes"
													item-text="name"
													item-value="value"
													dense
													outlined
												/>
											</v-col> -->
											<v-col>
												<validation-provider
													v-slot="{ errors }"
													name="phone"
													:rules="
														profile.phoneNumber === originProfile.phoneNumber
															? 'required|phone'
															: 'required|isPhoneNumberUnique|phone'
													"
												>
													<v-text-field
														v-model="profile.phoneNumber"
														:error-messages="errors"
														@keypress="allowOnlyNumber($event)"
														dense
														outlined
														label="휴대폰 번호"
													/>
												</validation-provider>
											</v-col>
										</v-row>
									</validation-observer>
								</v-col>
								<v-col cols="12" md="6">
									<v-text-field
										v-model="profile.name"
										dense
										outlined
										label="실명"
									/>
								</v-col>
								<v-col cols="12" md="6">
									<validation-provider
										v-slot="{ errors }"
										name="uid"
										:rules="
											profile.uid === originProfile.uid
												? 'required|userName'
												: 'required|isUserNameUnique|isUserNameAllowable|userName'
										"
									>
										<v-text-field
											v-model="profile.uid"
											:error-messages="errors"
											dense
											outlined
											label="유저명"
										/>
									</validation-provider>
								</v-col>
								<v-col cols="12" md="6">
									<v-text-field
										v-model="profile.birthDate"
										label="생년월일"
										dense
										outlined
										required
										@input="formatBirthDate"
										maxlength="10"
										:rules="[
											v =>
												/^\d{4}-\d{2}-\d{2}$/.test(v) ||
												'생년월일은 1990-01-01 형식이어야 합니다.',
										]"
									/>
								</v-col>

								<v-col cols="12" md="6" class="d-flex">
									<my-page-setting-password-field :profile="profile" />
								</v-col>

								<v-col cols="12" md="6" class="d-flex">
									<v-col cols="9" class="pa-0 pr-1">
										<v-text-field
											v-model="profile.recommenderReferralCode"
											dense
											outlined
											label="추천인(이메일 or 유저명)"
										/>
									</v-col>
									<v-col cols="3" class="pa-0">
										<v-btn
											@click="inquiryReferralCode"
											:disabled="profile.recommenderReferralCode ? false : true"
											:loading="inquiryLoading"
											color="primary"
											class="inquiry-btn full-width"
										>
											조회
										</v-btn>
									</v-col>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-card class="pa-5">
										<v-card-title class="pl-0 pt-0 pb-0">
											계정 연결
										</v-card-title>
										<v-divider class="my-5" />
										<h3>Kakao</h3>
										<div class="d-flex">
											<v-row no-gutters>
												<v-col
													cols="12"
													md="9"
													xs="12"
													class="d-flex align-center pl-0"
												>
													<div class="d-flex justify-start align-center">
														<v-img
															contain
															class="mr-2"
															:src="require('@/assets/logo/kakao-logo.png')"
															width="26"
															height="26"
														/>
														<span class="text-sm mr-5">카카오 계정</span>
														<strong>
															{{
																profile.kakaoId
																	? '연결 완료'
																	: '연결된 계정 없음'
															}}
														</strong>
													</div>
												</v-col>
												<v-col cols="12" md="3" sm="12">
													<div
														class="d-flex"
														:class="isSmAndDown ? 'mt-2' : 'justify-end'"
													>
														<v-btn
															v-if="profile.kakaoId"
															color="secondary"
															@click="unlinkSigninKakao"
														>
															연결 해제하기
														</v-btn>
														<kakao-signin-button
															:title="'연결하기'"
															:button-width="'100px'"
															:redirect-url="kakaoRedirectUrl"
															v-else
														/>
													</div>
												</v-col>
											</v-row>
										</div>
									</v-card>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12" class="d-flex justify-end">
									<v-btn color="primary" class="mt-4" @click="save">
										저장하기
									</v-btn>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
				</v-card>
				<div class="d-flex justify-start pt-2">
					<router-link
						:to="{ name: 'mypage-setting-withdrawal' }"
						class="text-decoration-none withdrawal-btn"
					>
						회원탈퇴
						<v-icon size="18">mdi-chevron-right</v-icon>
					</router-link>
				</div>
			</v-tab-item>
			<v-tab-item>
				<my-page-setting-transactions />
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import {
	ref,
	computed,
	reactive,
	watch,
	nextTick,
	onMounted,
} from '@vue/composition-api'

import i18n from '@/i18n'

import store from '@/store'
import { useRouter, getVuetify } from '@core/utils'
import { internationalPhoneFormat } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import UserService from '@/services/UserService'
import AuthService from '@/services/AuthService'

import KakaoSigninButton from '@/pages/components/KakaoSigninButton.vue'

import MyPageSettingPasswordField from './components/MyPageSettingPasswordField.vue'

const FILE_SIZE_BYTE_LIMIT = 20000000

export default {
	components: {
		MyPageSettingPasswordField,
		KakaoSigninButton,
	},
	setup() {
		const $vuetify = getVuetify()
		const { router } = useRouter()
		const file = ref(null)
		const tab = ref(null)
		const profile = ref(null)
		const originProfile = ref(null)
		const emailCodeInput = ref(null)
		const phoneNumberCodeInput = ref(null)
		const kakaoRedirectUrl = ref(`${window.location.origin}/auth/kakao-link`)
		const tabs = ref(['내 정보'])
		const menu = ref(false)
		const activePicker = ref(null)
		const isEmailValid = ref(false)
		const isPhoneNumberValid = ref(false)
		const isReferralCodeValid = ref(false)
		const inquiredReferralCode = ref(null)

		const verificationEmailCode = ref('')
		const isVerificationEmailCodeRequested = ref(false)
		const requestVerificationEmailCodeLoading = ref(false)
		const verifyEmailCodeLoading = ref(false)

		const inquiryLoading = ref(false)
		const countryCode = ref('82')
		const countryCodes = reactive([
			{
				name: '대한민국+82',
				value: '82',
			},
		])

		const init = async () => {
			try {
				await UserService.getMe()
				const data = computed(() => store.getters['user/getMe'])
				const {
					publicPictureUrl,
					email,
					emailVerified,
					uid,
					name,
					birthDate,
					phoneNumber,
					phoneNumberVerified,
					ciVerified,
					kakaoId,
					recommenderReferralCode,
				} = data.value

				profile.value = {
					publicPictureUrl,
					email,
					emailVerified,
					uid,
					name,
					birthDate,
					phoneNumber: phoneNumber ? '0' + phoneNumber.substring(2, 12) : '',
					phoneNumberVerified,
					ciVerified,
					kakaoId,
					recommenderReferralCode,
				}

				originProfile.value = JSON.parse(JSON.stringify(profile.value))
			} catch (e) {
				console.error(e)
			}
		}

		onMounted(async () => {
			init()
		})

		watch(
			() => menu.value,
			currentValue => {
				currentValue && setTimeout(() => (activePicker.value = 'YEAR'))
			},
		)

		const allowOnlyNumber = $event => {
			const keyCode = $event.keyCode ? $event.keyCode : $event.which
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault()
			}
		}

		const uploadProfileFile = async $event => {
			const form = new FormData()
			file.value = $event.target.files[0]
			form.append('data', file.value)

			if (file.value.size > FILE_SIZE_BYTE_LIMIT) {
				warningSwal('파일은 20MB이하로 업로드 가능합니다')
				return
			}

			try {
				await UserService.uploadProfileFile(form)

				const confirm = await successSwal({
					html: '프로필을 업로드했습니다',
					allowOutsideClick: false,
				})

				if (confirm.isConfirmed) {
					router.go()
				}
			} catch (e) {
				warningSwal('프로필을 업로드하는데 문제가 발생했습니다')
			}
		}

		const requestVerificationEmail = async () => {
			requestVerificationEmailCodeLoading.value = true
			try {
				await AuthService.generateEmailVerificationCode()
				isVerificationEmailCodeRequested.value = true

				nextTick(() => {
					emailCodeInput.value.$refs.input.focus()
				})
			} catch (e) {
				warningSwal('인증코드를 발송하는데 문제가 발생되었습니다')
			} finally {
				requestVerificationEmailCodeLoading.value = false
			}
		}

		const verifyEmailCode = async () => {
			if (verificationEmailCode.value === '') {
				warningSwal('인증코드를 입력하세요')
				return
			}

			verifyEmailCodeLoading.value = true
			try {
				await AuthService.verificationEmailCode(verificationEmailCode.value)
				isVerificationEmailCodeRequested.value = false

				await successSwal({
					html: '인증되었습니다',
					allowOutsideClick: false,
				})

				router.go()
			} catch (e) {
				warningSwal('인증하는데 문제가 발송되었습니다')
			} finally {
				verifyEmailCodeLoading.value = false
			}
		}

		const updateUserCertification = async imp_uid => {
			try {
				const payload = {
					iamportUid: imp_uid,
					phoneNumber: internationalPhoneFormat(profile.value.phoneNumber),
				}

				await AuthService.updateUserCertification(payload)

				const confrim = await successSwal({
					html: '본인인증이 완료되었습니다',
					allowOutsideClick: false,
				})
				if (confrim.isConfirmed) {
					router.go()
				}
			} catch (e) {
				warningSwal(
					e.response.status === 400
						? e.response.data.message
						: '인증정보를 불러오는데 문제가 발생되었습니다',
				)
			}
		}

		const callback = async response => {
			/* 3. 콜백 함수 정의하기 */
			const { success, imp_uid, error_msg } = response

			if (success) {
				updateUserCertification(imp_uid)
			} else {
				warningSwal(error_msg)
			}
		}

		const isMobile = () => {
			const device = navigator.userAgent
			if (
				/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
					device,
				)
			) {
				return true
			}
		}

		const onCertification = () => {
			/* 1. 가맹점 식별하기 */
			const { IMP } = window
			IMP.init(process.env.VUE_APP_MERCHANT_ID_CODE)

			/* 2. 본인인증 데이터 정의하기 */
			const data = {
				merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
				name: '',
				phone: profile.value.phoneNumber,
				company: 'https://itmarketer.org/',
				popup: isMobile() ? true : false,
			}

			/* 4. 본인인증 창 호출하기 */
			IMP.certification(data, callback)
		}

		const unlinkSigninKakao = async () => {
			const confirm = await confirmSwal('카카오톡 계정 연결 해제하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await UserService.unlinkSigninKakao()
					successSwal('카카오톡 계정 연결 해제되었습니다')
					init()
				} catch (e) {
					warningSwal('카카오톡 계정을 연결 해제하는데 문제가 발생되었습니다')
				}
			}
		}

		const save = async () => {
			const changedValueKey = []
			const payload = {}
			for (const key in profile.value) {
				if (originProfile.value[key] !== profile.value[key]) {
					payload[key] = profile.value[key]
					changedValueKey.push(true)
				}
			}

			if (changedValueKey.indexOf(true) === -1) {
				warningSwal('변경된 정보가 없습니다')
				return
			}

			try {
				if (payload.recommenderReferralCode && !isReferralCodeValid.value) {
					warningSwal('추천인 정보를 조회해주세요')
					return
				}

				if (payload.phoneNumber) {
					payload.phoneNumber = internationalPhoneFormat(payload.phoneNumber)
				}

				if (
					Object.prototype.hasOwnProperty.call(
						payload,
						'recommenderReferralCode',
					)
				) {
					delete payload.recommenderReferralCode
					await UserService.putMeRecommender(inquiredReferralCode.value)
				}

				await UserService.putMe(payload)
				const confirm = await successSwal({
					html: '내 정보가 저장되었습니다',
					allowOutsideClick: false,
				})
				if (confirm.isConfirmed) {
					UserService.getMe()
				}
			} catch (e) {
				warningSwal('내 정보를 저장하는데 문제가 발생했습니다')
			}
		}

		const inquiryReferralCode = async () => {
			try {
				inquiryLoading.value = true
				const data = await AuthService.inquiryReferralCode(
					profile.value.recommenderReferralCode,
				)
				inquiredReferralCode.value = data.referralCode
				isReferralCodeValid.value = true
				successSwal({
					title: i18n.t('signupForm.referrerInquiryMsg.success.title'),
					html: i18n.t('signupForm.referrerInquiryMsg.success.html', {
						name: data.uid,
					}),
					confirmButtonText: i18n.t(
						'signupForm.referrerInquiryMsg.success.confirmButtonText',
					),
				})
			} catch (e) {
				if (e) {
					isReferralCodeValid.value = false
					warningSwal({
						title: i18n.t('signupForm.referrerInquiryMsg.fail.title'),
						html: i18n.t('signupForm.referrerInquiryMsg.fail.html'),
						confirmButtonText: i18n.t(
							'signupForm.referrerInquiryMsg.fail.confirmButtonText',
						),
					})
				}
			} finally {
				inquiryLoading.value = false
			}
		}

		const formatBirthDate = value => {
			// 숫자와 하이픈만 남기고 모두 제거
			let newValue = value.replace(/[^\d-]/g, '')

			// 하이픈을 모두 제거
			newValue = newValue.replace(/-/g, '')

			// 날짜 형식에 맞게 하이픈 추가
			if (newValue.length >= 4 && newValue.length < 6) {
				newValue = newValue.substring(0, 4) + '-' + newValue.substring(4)
			} else if (newValue.length >= 6) {
				newValue =
					newValue.substring(0, 4) +
					'-' +
					newValue.substring(4, 6) +
					'-' +
					newValue.substring(6, 8)
			}

			profile.value.birthDate = newValue
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const isSmAndup = computed(() => {
			return $vuetify.breakpoint.smAndUp
		})

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		return {
			menu,
			tab,
			tabs,
			activePicker,
			profile,
			isSmAndup,
			isMdAndUp,
			isSmAndDown,
			originProfile,
			inquiryLoading,
			verificationEmailCode,
			isVerificationEmailCodeRequested,
			requestVerificationEmailCodeLoading,
			verifyEmailCodeLoading,
			isEmailValid,
			isPhoneNumberValid,
			isReferralCodeValid,
			countryCode,
			countryCodes,
			emailCodeInput,
			phoneNumberCodeInput,
			kakaoRedirectUrl,

			unlinkSigninKakao,
			requestVerificationEmail,
			verifyEmailCode,

			allowOnlyNumber,
			onCertification,

			inquiryReferralCode,
			uploadProfileFile,
			save,
			formatBirthDate,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-tab {
	margin-left: 0 !important;
}

.full-width {
	width: 100% !important;
	max-width: 100% !important;
}

.withdrawal-btn {
	font-size: 15px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: normal;
	color: #767678;
}

.verification-btn,
.inquiry-btn {
	width: 100%;
	min-width: 100% !important;
	min-height: 40px;
}

::v-deep {
	.v-text-field__prefix {
		color: gray;
	}
}
</style>
